/**
* Copyright 2017 Google Inc.
*
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
*   http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
*/
/**
 * Do a deep-copy of basic JavaScript Objects or Arrays.
 */
export function deepCopy<T>(value: T): T {
  return deepExtend(undefined, value);
}

/**
 * Copy properties from source to target (recursively allows extension
 * of Objects and Arrays).  Scalar values in the target are over-written.
 * If target is undefined, an object of the appropriate type will be created
 * (and returned).
 *
 * We recursively copy all child properties of plain Objects in the source- so
 * that namespace- like dictionaries are merged.
 *
 * Note that the target can be a function, in which case the properties in
 * the source Object are copied onto it as static properties of the Function.
 */
export function deepExtend(target: any, source: any): any {
  if (!(source instanceof Object)) {
    return source;
  }

  switch (source.constructor) {
  case Date:
    // Treat Dates like scalars; if the target date object had any child
    // properties - they will be lost!
    let dateValue = (source as any) as Date;
    return new Date(dateValue.getTime());

  case Object:
    if (target === undefined) {
      target = {};
    }
    break;

  case Array:
    // Always copy the array source and overwrite the target.
    target = [];
    break;

  default:
    // Not a plain Object - treat it as a scalar.
    return source;
  }

  for (let prop in source) {
    if (!source.hasOwnProperty(prop)) {
      continue;
    }
    target[prop] = deepExtend(target[prop], source[prop]);
  }

  return target;
}

// TODO: Really needed (for JSCompiler type checking)?
export function patchProperty(obj: any, prop: string, value: any) {
  obj[prop] = value;
}