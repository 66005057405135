/**
* Copyright 2017 Google Inc.
*
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
*   http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
*/
import {FirebaseStorageError} from './error';
import * as promiseimpl from './promise_external';
import * as RequestExports from './request';
import {Request} from './request';

/**
 * A request whose promise always fails.
 * @struct
 * @template T
 */
export class FailRequest<T> implements Request<T> {
  promise_: Promise<T>;

  constructor(error: FirebaseStorageError) {
    this.promise_ = promiseimpl.reject<T>(error);
  }

  /** @inheritDoc */
  getPromise() {
    return this.promise_;
  }

  /** @inheritDoc */
  cancel(appDelete = false) {}
}
